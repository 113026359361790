import React, { useRef, useState } from 'react';
import { Spacer } from '@rmstransactions/components';
import { validation, Svg } from '@rmstransactions/components';
import {
  emailAddressValidationError,
  validationData,
  invalidEmailCharacters,
} from 'utils/helper';
import {
  IconEmail,
  Modal,
  Input,
  Button,
  InPageAlert,
} from '@snsw/react-component-library';
import { IconLabelWrapper } from 'pages/GoodBehaviourPage/GoodBehaviourPage.styled';
import { sendEmailReceipt } from 'services/ElectGoodBehaviourService';
import errorMessages from 'components/Error/errorMessages';
import {
  EmailContainer,
  EmailInlineWrapper,
  StyledFormGroup,
  TextWrap,
} from './EmailForm.styled';
import { Constants } from 'constants/constants';
import { IEmailRequest } from 'interfaces/IConfirmationDetails';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { emailDetailsSliceActions } from 'redux/slice/emailDetails';
import ConfirmationUUIDManager from 'utils/session/ConfirmationUUIDManager';

interface AppEmailFormProps {
  uuid?: string;
  emailInputRef?: any;
  drivingRecordLinkRef?: any;
}

const EmailForm: React.FC<AppEmailFormProps> = ({
  uuid,
  emailInputRef,
  drivingRecordLinkRef,
}) => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [emailSubmitStatus, setEmailSubmitStatus] = useState<string>('');
  const { secondaryEmailStored } = useStoreSelector((state) => ({
    secondaryEmailStored: state.emailDetails.secondaryEmail,
  }));
  const dispatch = useStoreDispatch();
  const emailSuccessMsg = 'Your email has been sent to ' + secondaryEmailStored;
  const sendButtonRef = useRef<HTMLInputElement | null>(null);

  function onChangeEmailAddress(e: React.ChangeEvent<HTMLInputElement>) {
    const email = e.target.value;
    setEmailValue(email);
    if (invalidEmailCharacters(email)) {
      setHasEmailError(true);
      setEmailErrorMessage(validationData['EMAIL_INVALID']);
    } else {
      setHasEmailError(false);
    }
  }

  const handleSendButton = () => {
    if (!validation.email(emailValue)) {
      setEmailErrorMessage(errorMessages.email.isValid);
      setHasEmailError(true);
      return;
    }
    setShowModal(true);
  };

  const handleSendEmailReceipt = async () => {
    setShowModal(false);
    setEmailSubmitStatus('loading');
    const emailRequest: IEmailRequest = {
      emailAddress: emailValue,
      uuid: uuid ?? ConfirmationUUIDManager.getValue(),
    };
    try {
      const response = await sendEmailReceipt(emailRequest);
      if (response.status !== 200) {
        setEmailSubmitStatus('ERROR');
      } else {
        setEmailSubmitStatus('SUCCESS');
        dispatch(emailDetailsSliceActions.setSecondaryEmail(emailValue));
        if (typeof response.data === 'string') {
          ConfirmationUUIDManager.setValue(response.data);
        }
      }
      drivingRecordLinkRef.current.focus();
    } catch (error) {
      setEmailSubmitStatus('ERROR');
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setTimeout(() => {
      sendButtonRef.current.focus();
    }, 250);
  };

  function onBlurEmailAddress() {
    validateEmailInput(emailValue);
  }

  function validateEmailInput(email: string) {
    email = email ?? '';
    const validateEmailError = emailAddressValidationError(email);
    if (validateEmailError === 'EMAIL_INVALID') {
      setEmailErrorMessage(validationData[validateEmailError]);
      setHasEmailError(true);
      return true;
    } else {
      setHasEmailError(false);
      return false;
    }
  }

  return (
    <EmailContainer>
      {showModal && (
        <Modal
          title='Confirm email address'
          buttons={[
            { text: 'Send', onClick: handleSendEmailReceipt },
            { text: 'Cancel', onClick: handleModalClose },
          ]}
          onClose={handleModalClose}
        >
          <TextWrap>
            For your security, please confirm you&apos;d like your receipt to be
            sent to <strong className='h-word-break'>{emailValue}</strong>
          </TextWrap>
        </Modal>
      )}
      <IconLabelWrapper>
        <div className='iconWrapper'>
          <IconEmail />
        </div>
        <p>Send receipt to this email address</p>
      </IconLabelWrapper>
      {emailSubmitStatus === 'ERROR' && (
        <>
          <Spacer mt={-1} />
          <InPageAlert
            variant='error'
            title='Email failed to send'
            data-testid='sendEmailError'
          >
            We were unable to send your email, please try again. Need help? Call
            us on 13 77 88 or visit a{' '}
            <a
              href={Constants.SERVICE_CENTER_LINK}
              target={'_blank'}
              rel='noopener noreferrer'
            >
              service centre
            </a>
          </InPageAlert>
        </>
      )}
      {emailSubmitStatus === 'SUCCESS' && (
        <>
          <Spacer mt={-1} />

          <InPageAlert
            variant='success'
            title={emailSuccessMsg}
            data-testid='sendEmailError'
          ></InPageAlert>
        </>
      )}
      <EmailInlineWrapper>
        <StyledFormGroup
          label='Send receipt to this email address' //Form group label added
          id='formInputEmail'
          errorMessage={emailErrorMessage}
          hasError={hasEmailError}
        >
          <Input
            onBlur={onBlurEmailAddress}
            onChange={onChangeEmailAddress}
            value={emailValue}
            className='formInputEmail'
            data-testid='formInputEmail'
            inputRef={emailInputRef}
            inputWidth='lg'
            label=''
            style={{ marginTop: '-0.625rem' }}
          />
        </StyledFormGroup>
        <Button
          id='sendButton'
          theme='primary'
          className='sendButton'
          data-testid='sendButton'
          ref={sendButtonRef}
          onClick={handleSendButton}
          style={{ marginTop: '0.375rem' }}
        >
          <>
            <b>
              {emailSubmitStatus === 'loading' ? (
                <Svg.Spinner color='#646974' radius='' visible='' stroke='' />
              ) : (
                ' Send'
              )}
            </b>
          </>
        </Button>
      </EmailInlineWrapper>
    </EmailContainer>
  );
};

export default EmailForm;
