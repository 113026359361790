import {
  HttpRequestHandler,
  UserSession,
  UserToken,
  AnalyticsSessionManager,
  analyticsServiceV2,
} from '@rmstransactions/components';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { Config } from '../config/env';
import { IValidateRequest } from 'interfaces/IValidateCustomer';
import { debugLogger } from 'services/LoggerService';
import { IEligibilityRequest } from 'interfaces/IEligibilityDetails';
import { Constants } from 'constants/constants';
import { handleEGBAPIErrors } from './EGBErrorService';
import {
  IConfirmationResponse,
  IEligibilityResponse,
  IProcessResponse,
  IValidateResponse,
} from 'interfaces/IResponse';
import { IUserType } from 'interfaces/IUserType';
import { History } from 'history';
import { IProcessRequest } from 'interfaces/IDeclarationDetails';
import { IEmailRequest } from 'interfaces/IConfirmationDetails';

const {
  apiHost,
  myAccDashboardUrl,
  apiLoginPath,
  apiCheckLoginPath,
  apiMyAccCreateAccountPath,
  apiGetCustomerLicenceDetailsAuthPath,
  apiValidateCustomerAuthPath,
  apiValidateCustomerAnonPath,
  apiUserTypePath,
  apiEligibilityAuthPath,
  apiEligibilityAnonPath,
  apiSendEmailAuthPath,
  apiSendEmailAnonPath,
  apiSendEmailSupportPath,
  apiProcessRequestAuthPath,
  apiProcessRequestAnonPath,
  apiConfirmationAuthPath,
  apiConfirmationAnonPath,
  apiConfirmationSupportPath,
} = Config;

HttpRequestHandler.setCommonApiKeyHeader();

export const getUserType = async (): Promise<AxiosResponse<IUserType>> => {
  const url =
    apiHost + apiUserTypePath + `?state=${Constants.TRANSACTION_STATE}`;
  return await axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const silentLogin = () => {
  debugLogger('Silent login Url invoked--->' + Constants.SILENTAUTHURL);
  UserSession.silentLogin(Constants.SILENTAUTHURL);
};

export const handleButtonClickGA = (
  eventName: string,
  eventAction: string,
  destinationUrl: string
) => {
  analyticsServiceV2.pushOnButtonClick({
    action: eventAction,
    buttonName: eventName,
    transactionStartTime: AnalyticsSessionManager.getTransactionStartTime(),
    destinationUrl: destinationUrl,
  });
};
export const callLogin = (
  eventName: string,
  eventAction: string,
  pageUrl: string
) => {
  handleButtonClickGA(eventName, eventAction, pageUrl);
  const loginUrl = `${apiHost}${apiLoginPath}?state=EGB`;
  debugLogger('loginUrl invoked--->' + loginUrl);
  window.location.href = loginUrl;
  UserSession.login(loginUrl);
};

export const myAccCreateAccount = (
  eventName: string,
  eventAction: string,
  pageUrl: string
) => {
  handleButtonClickGA(eventName, eventAction, pageUrl);
  const myAccountDashboardUrl = `${myAccDashboardUrl}${apiMyAccCreateAccountPath}`;
  debugLogger('MyAccount create account invoked--->' + myAccountDashboardUrl);
  window.location.href = myAccountDashboardUrl;
};

export const validateCustomer = async (
  data: IValidateRequest
): Promise<AxiosResponse<IValidateResponse>> => {
  const path = UserSession.isLoginUser()
    ? apiValidateCustomerAuthPath
    : apiValidateCustomerAnonPath;
  const url = `${apiHost}${path}`;
  debugLogger('validateRequest', data);
  return axios.post(url, data, {
    headers: {
      'x-token': UserToken.getValue(),
    },
    withCredentials: true,
  });
};

export const checkEligibilityApi = async (
  data: IEligibilityRequest
): Promise<AxiosResponse<IEligibilityResponse>> => {
  const path = UserSession.isLoginUser()
    ? apiEligibilityAuthPath
    : apiEligibilityAnonPath;
  const url = `${apiHost}${path}`;
  debugLogger('eligibilityRequest', data);
  return await axios.post(url, data, {
    headers: {
      'x-token': UserToken.getValue(),
    },
    withCredentials: true,
  });
};

export const processSubmitApi = async (
  data: IProcessRequest
): Promise<AxiosResponse<IProcessResponse>> => {
  const path = UserSession.isLoginUser()
    ? apiProcessRequestAuthPath
    : apiProcessRequestAnonPath;
  const url = `${apiHost}${path}`;
  return axios.post(url, data, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getCustomerLicenceDetails = async (): Promise<
  AxiosResponse<IValidateResponse>
> => {
  const url = `${apiHost}${apiGetCustomerLicenceDetailsAuthPath}`;
  return axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const sendEmailReceipt = async (
  request: IEmailRequest
): Promise<any> => {
  const path = UserSession.isLoginUser()
    ? apiSendEmailAuthPath
    : apiSendEmailAnonPath;
  const url = `${apiHost}${path}`;
  debugLogger('sendEmailReceipt', request);

  if (UserToken.getValue()) {
    return axios.post(url, request, {
      withCredentials: true,
      headers: { 'x-token': UserToken.getValue() },
    });
  } else {
    const url = `${apiHost}${apiSendEmailSupportPath}`;
    return axios.post(url, request, {
      withCredentials: true,
    });
  }
};

export const getConfirmationData = async (
  uuid = ''
): Promise<AxiosResponse<IConfirmationResponse>> => {
  const path = UserSession.isLoginUser()
    ? apiConfirmationAuthPath
    : apiConfirmationAnonPath;
  const url = `${apiHost}${path}`;
  debugLogger('getConfirmationData', uuid);

  if (UserToken.getValue()) {
    return axios.get(url, {
      withCredentials: true,
      headers: { 'x-token': UserToken.getValue() },
    });
  } else {
    if (uuid) {
      const url = `${apiHost}${apiConfirmationSupportPath}/${uuid}`;
      return axios.get(url, {
        withCredentials: false,
      });
    } else {
      const url = `${apiHost}${apiConfirmationSupportPath}`;
      return axios.get(url, {
        withCredentials: false,
      });
    }
  }
};

export const checkLogin = async (): Promise<any> => {
  const url = `${Config.apiHost}${apiCheckLoginPath}`;
  return await fetch(url, {
    headers: { 'x-api-key': process.env.REACT_APP_GATEWAY_PROXY_API_KEY },
    credentials: 'include',
  });
};

export const interceptors = (history: History) => {
  HttpRequestHandler.setCommonContentType('application/json');

  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    return config;
  });

  axios.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      handleEGBAPIErrors(error, history);
    }
  );
};
