import { Spacer } from '@rmstransactions/components';
import {
  Box,
  H2,
  ComponentLoader,
  ContentContainer,
  Row,
  Col,
  HorizontalRule,
  InPageAlert,
} from '@snsw/react-component-library';
import { Constants } from 'constants/constants';
import React, { useEffect, useRef, useState } from 'react';
import EmailForm from 'components/EmailForm/EmailForm';
import WhatHappensNext from './WhatHappensNext/WhatHappensNext';
import { ColWrapper } from './GoodBehaviourPage.styled';
import moment from 'moment';
import LicenceWarning from './LicenceWarning/LicenceWarning';
import ThumbsUpThumbsDown from './ThumbsUpThumbsDown/ThumbsUpThumbsDown';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  handleButtonClickGA,
  getConfirmationData,
  interceptors,
} from 'services/ElectGoodBehaviourService';
import { RoutePathParams } from 'interfaces/IConfirmationDetails';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { confirmationSliceActions } from 'redux/slice/confirmation';
import { helpers } from '@rmstransactions/components';
import KeepYourDetails from './KeepYourDetails/KeepYourDetails';
import KeepAReceipt from './KeepAReceipt/KeepAReceipt';
import ActivityAlert from './ActivityAlert/ActivityAlert';
import { handleResponseData } from 'utils/api/httpClient';
import { handleErrorInResponse } from 'services/EGBErrorService';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import ProgressStepperComp from 'components/ProgressStepper/ProgressStepperComp';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { emailUpdateInfoMessage } from 'config/errorMessages';
import RefreshAlert from 'components/RefreshAlert/RefreshAlert';
import ConfirmationUUIDManager from 'utils/session/ConfirmationUUIDManager';
import { confirmationHistoryState } from 'utils/route/urlParams';

const GoodBehaviourPage = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const urlParams = useParams<RoutePathParams>();
  const location = useLocation();
  const locationState = location?.state as confirmationHistoryState;
  const receiptUUID =
    urlParams.receiptUUID ??
    ConfirmationUUIDManager.getValue() ??
    locationState?.receiptUUID;
  const transactionDate = helpers.dateFormat(
    new Date().toString(),
    'D MMMM YYYY'
  );
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] =
    useState<DriveMessageDetails | null>(null);
  const {
    userType,
    receiptNumber,
    licenceExpiryDate,
    suspensionStartDate,
    suspensionEndDate,
    businessTransaction,
    emailAddress,
    mailingAddress,
    receiptDataPDFBase64,
    emailUpdateRequiredStored,
  } = useStoreSelector((state) => ({
    userType: state.userType.type,
    receiptNumber: state.confirmation.receiptNumber,
    licenceExpiryDate: state.confirmation.licenceExpiryDate,
    suspensionStartDate: state.confirmation.egbStartDate,
    suspensionEndDate: state.confirmation.egbEndDate,
    businessTransaction: state.confirmation.businessTransaction,
    emailAddress: state.confirmation.emailAddress,
    mailingAddress: state.confirmation.mailingAddress,
    receiptDataPDFBase64: state.confirmation.receiptDataPDFBase64,
    emailUpdateRequiredStored: state.emailDetails.emailUpdateRequired,
  }));

  const emailUpdateRequired = emailUpdateRequiredStored === 'Yes';
  const expiryDate = moment(licenceExpiryDate, 'YYYY-MM-DD');
  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );

  console.log('test');

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.DECLARATION_PAGE_URL;

  const handleConfirmationApi = async (uuid: string) => {
    setIsLoading(true);
    const confirmationData = await getConfirmationData(uuid);
    const data = handleResponseData(confirmationData);
    setIsLoading(false);
    if (!data) {
      return;
    }
    const { response, systemMessages } = data;
    dispatch(confirmationSliceActions.setSystemMessage(systemMessages));
    const inlineMessage = handleErrorInResponse(history, systemMessages);
    if (inlineMessage) {
      setNotificationMessage(inlineMessage);
    }
    dispatch(confirmationSliceActions.setConfirmationData(response));

    if (response?.receiptUUID) {
      ConfirmationUUIDManager.setValue(response?.receiptUUID);
    }
  };

  useEffect(() => {
    //condition to check the store value for refresh scenario
    if (userType === null && !receiptUUID) {
      history.push('/');
    }
  });

  useEffect(() => {
    setIsLoading(false);
    interceptors(history);
    handleConfirmationApi(receiptUUID ?? '');
  }, [receiptUUID]);

  const receiptFilename = `Elect Good Behaviour Receipt`;
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const drivingRecordLinkRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (eventAction: string, eventName: string) => {
    handleButtonClickGA(eventName, eventAction, pageUrl);
  };

  return (
    <>
      <ComponentLoader fullPage label={loadingText} active={isLoading} />
      <ProgressStepperComp
        steps={4}
        label='Apply for a good behaviour period'
        title='Good behaviour application complete'
      />
      <ContentContainer>
        <Spacer mt='-3rem' />
        <Row>
          <ColWrapper>
            <InPageAlert
              variant='success'
              title='Your good behaviour period application has been successful'
            ></InPageAlert>
            <Spacer mt='-1rem' />
            <NotificationMessage
              message={notificationMessage}
              marginTop={true}
            />
          </ColWrapper>
        </Row>
        <Row>
          <ColWrapper>
            <Spacer mt='1rem' />
            <H2>Application details </H2>
            <Spacer mt='2rem' />
            <Box style={{ margin: '1rem 0 0', maxWidth: '100%' }}>
              <Row>
                <Col span={3.5}>
                  <p>Good behaviour period:</p>
                </Col>
                <Col>
                  {businessTransaction === Constants.ITOP1 ? (
                    <p>
                      <strong>
                        {suspensionStartDate} to {suspensionEndDate}
                      </strong>
                    </p>
                  ) : (
                    <p>
                      <strong>
                        Starts {suspensionStartDate}, or the date you pass the
                        driver knowledge test (whichever is the later)
                      </strong>
                    </p>
                  )}
                </Col>
              </Row>
              <Spacer mt='1rem' />
              <Row>
                <Col span={3.5}>
                  <p>Receipt number:</p>
                </Col>
                <Col>
                  <p>{receiptNumber}</p>
                </Col>
              </Row>
              <Spacer mt='1rem' />
              <Row>
                <Col span={3.5}>
                  <p>Date of transaction:</p>
                </Col>
                <Col>
                  <p>{transactionDate}</p>
                </Col>
              </Row>
            </Box>
          </ColWrapper>
        </Row>
        <Spacer mt={2.5} />
        <Row>
          <ColWrapper>
            <ActivityAlert inputRef={emailInputRef} />
          </ColWrapper>
        </Row>
        {emailUpdateRequired && (
          <Row>
            <ColWrapper>
              <NotificationMessage message={emailUpdateInfoMessage} />
              <Spacer mb={-1} />
            </ColWrapper>
          </Row>
        )}
        <Row>
          <ColWrapper>
            <KeepAReceipt
              receiptFileName={receiptFilename}
              receiptPDFBase64={receiptDataPDFBase64}
              onClick={handleClick}
            />
            <Spacer mb={2}>
              <EmailForm
                uuid={receiptUUID}
                emailInputRef={emailInputRef}
                drivingRecordLinkRef={drivingRecordLinkRef}
              />
            </Spacer>
          </ColWrapper>
        </Row>
        <Row>
          <ColWrapper>
            <WhatHappensNext
              businessTransaction={businessTransaction}
              isDKTToComplete={
                notificationMessage?.identifier === 'CUSTOMER_TO_COMPLETE_DKT'
              }
              drivingRecordLinkRef={drivingRecordLinkRef}
            />
          </ColWrapper>
        </Row>
        <Row>
          <ColWrapper>
            {expiryDate.isAfter(moment().add(6, 'weeks')) &&
              expiryDate.isBefore(moment().add(6, 'months')) && (
                <LicenceWarning licenceExpiryDate={licenceExpiryDate} />
              )}
          </ColWrapper>
        </Row>
        <Row>
          <ColWrapper>
            <Spacer mt='-2rem' />
            <HorizontalRule
              className='horizontalRule'
              marginTop='1.937rem'
              marginBottom='3.5rem'
            />
            <KeepYourDetails
              emailAddress={emailAddress}
              mailingAddress={mailingAddress}
              userType={userType}
            />
          </ColWrapper>
        </Row>
        <Row>
          <ColWrapper>
            <Spacer mt='2rem' />
            <ThumbsUpThumbsDown
              applicationName={Constants.EGB_TUTD_APP_NAME}
              label='How was your experience?'
            />
          </ColWrapper>
        </Row>
      </ContentContainer>
      <RefreshAlert />
    </>
  );
};

export default GoodBehaviourPage;
