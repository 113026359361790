import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfirmationDetails } from 'interfaces/IConfirmationDetails';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';

export interface ConfirmationState {
  receiptNumber: string | null;
  emailAddress: string | null;
  mailingAddress: string | null;
  receiptDataPDFBase64: string | null;
  egbStartDate: string | null;
  egbEndDate: string | null;
  licenceExpiryDate: string | null;
  businessTransaction: string | null;
  isGuest: boolean;
  systemMessage: DriveMessageDetails | null;
  receiptUUID: string | null;
}

export const initialState: ConfirmationState = {
  receiptNumber: null,
  emailAddress: null,
  mailingAddress: null,
  receiptDataPDFBase64: null,
  egbStartDate: null,
  egbEndDate: null,
  licenceExpiryDate: null,
  businessTransaction: null,
  isGuest: false,
  systemMessage: null,
  receiptUUID: null,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmationData: (
      state,
      action: PayloadAction<IConfirmationDetails>
    ) => {
      const confirmationData = action.payload;
      state.receiptNumber = confirmationData.receiptNumber;
      state.emailAddress = confirmationData.emailAddress;
      state.mailingAddress = confirmationData.mailingAddress;
      state.receiptDataPDFBase64 = confirmationData.receiptDataPDFBase64;
      state.egbStartDate = confirmationData.egbStartDate;
      state.egbEndDate = confirmationData.egbEndDate;
      state.licenceExpiryDate = confirmationData.licenceExpiryDate;
      state.businessTransaction = confirmationData.businessTransaction;
      state.isGuest = confirmationData.isGuest;
      state.receiptUUID = confirmationData.receiptUUID;
    },
    setSystemMessage: (state, action: PayloadAction<DriveMessageDetails[]>) => {
      const systemMessages = action.payload;
      state.systemMessage = systemMessages?.[0];
    },
    resetConfirmationDetails: () => initialState,
  },
});

export const confirmationSliceActions = confirmationSlice.actions;
export const confirmationReducer = confirmationSlice.reducer;
